import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import BlogContent from '../components/BlogContent';
import LatestNews from '../components/LatestNews';

const BlogSingleTemplate = ({
  data: {
    contentfulSiteData: { siteName },
    contentfulBlog: {
      title,
      category,
      metaDescription,
      createdAt,
      metaCreatedDate,
      content,
    },
    allContentfulBlog: { nodes },
  },
}) => (
  <Layout
    isBlogSingle={true}
    title={title}
    description={metaDescription}
    siteName={siteName}
  >
    <main>
      <BlogContent
        title={title}
        category={category}
        createdAt={createdAt}
        metaCreatedDate={metaCreatedDate}
        richText={content}
      />
      <LatestNews heading="More MEE News" items={nodes} />
    </main>
  </Layout>
);

export const BlogSingleTemplateeQuery = graphql`
  query BlogSingleTemplateeQuery($id: String!) {
    contentfulSiteData {
      siteName
    }
    contentfulBlog(id: { eq: $id }) {
      title
      category {
        title
      }
      metaDescription
      createdAt(formatString: "DD MMMM YYYY")
      metaCreatedDate: createdAt(formatString: "YYYY-MM-DD")
      content {
        raw
        references {
          ...ContentFragment
        }
      }
    }
    allContentfulBlog(
      limit: 3
      sort: { order: DESC, fields: createdAt }
      filter: { id: { ne: $id } }
    ) {
      nodes {
        id
        title
        slug
        featuredImage {
          title
          gatsbyImageData(width: 620, height: 640)
        }
        createdAt(formatString: "DD MMMM YYYY")
        metaCreatedDate: createdAt(formatString: "YYYY-MM-DD")
        introText {
          introText
        }
        category {
          title
        }
      }
    }
  }
`;

export default BlogSingleTemplate;
