import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import {
  brandColours,
  breakpointSizes,
  fluidFontSize,
  fontSize,
  fontWeights,
  minBreakpointQuery,
  sectionMargins,
} from '../styles';
import { Container, HtmlContent, Link } from './ui';

const StyledBlogContent = styled.section`
  ${sectionMargins('30px', '50px')};
`;

const StyledBlogInfo = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;

  ${minBreakpointQuery.mlarge`
    gap: 20px;
  `}
`;

const StyledCategory = styled.span`
  ${fontSize(14)}
  line-height: 1.7;
  text-transform: uppercase;
  letter-spacing: 0.4em;
  font-weight: ${fontWeights.bold};
  background-color: ${brandColours.primary};
  padding: 8px 20px;
  flex-shrink: 0;
`;

const StyledDate = styled.time`
  ${fontSize(15)}
  flex-shrink: 0;
`;

const StyledHeading = styled.h1`
  margin-top: 30px;
  margin-bottom: 30px;
  font-weight: ${fontWeights.regular};
  ${fluidFontSize(
    '32px',
    '42px',
    breakpointSizes.tiny,
    breakpointSizes.xxlarge
  )};
  line-height: 1.2;

  ${minBreakpointQuery.medium`
    text-align: center;
  `}

  ${minBreakpointQuery.mlarge`
    margin-top: 40px;
    margin-bottom: 40px;
  `}
`;

const StyledImageOuter = styled.div`
  ${sectionMargins('30px', '50px')};
`;

const StyledImageInner = styled.div``;

const StyledImage = styled.figure`
  margin: 0;
`;

const StyledCaption = styled.figcaption`
  margin-top: 10px;
  ${fontSize(14)};
  line-height: 1.6;
  font-weight: ${fontWeights.bold};
  text-align: left;

  ${minBreakpointQuery.small`
    ${fontSize(15)};
  `}
`;

const StyledHtmlContent = styled(HtmlContent)`
  ${minBreakpointQuery.medium`
    text-align: center;
  `}

  p {
    ${minBreakpointQuery.medium`
      padding-left: 50px;
      padding-right: 50px;
    `}
  }

  blockquote {
    font-weight: ${fontWeights.bold};
    ${fluidFontSize(
      '32px',
      '42px',
      breakpointSizes.tiny,
      breakpointSizes.xxlarge
    )};

    p {
      padding: 0;
      font-size: inherit;
    }
  }
`;

const BlogContent = ({
  title,
  category,
  createdAt,
  metaCreatedDate,
  richText,
}) => {
  const options = {
    renderNode: {
      'embedded-asset-block': node => {
        const { gatsbyImageData, title, description } = node.data.target;
        if (!gatsbyImageData) {
          // asset is not an image
          return null;
        }

        return (
          <StyledImageOuter>
            <StyledImageInner>
              <StyledImage>
                <GatsbyImage image={gatsbyImageData} alt={title} />
                {description && <StyledCaption>{description}</StyledCaption>}
              </StyledImage>
            </StyledImageInner>
          </StyledImageOuter>
        );
      },
      'entry-hyperlink': node => {
        const { slug } = node.data.target;
        if (!slug) {
          return null;
        }

        return <Link to={slug}>{node.content[0].value}</Link>;
      },
      'asset-hyperlink': node => {
        const { url } = node.data.target;
        if (!url) {
          return null;
        }
        return (
          <Link href={url} target="_blank" rel="noopener">
            {node.content[0].value}
          </Link>
        );
      },
    },
  };

  return (
    <Container>
      <StyledBlogContent>
        <StyledBlogInfo>
          <StyledCategory>{category.title}</StyledCategory>
          <StyledDate dateTime={metaCreatedDate}>{createdAt}</StyledDate>
        </StyledBlogInfo>
        <StyledHeading>{title}</StyledHeading>
        <StyledHtmlContent richText={richText} options={options} />
      </StyledBlogContent>
    </Container>
  );
};

export default BlogContent;
